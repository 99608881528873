<template>
	<div class="page-box hasnav notab">
		<navBar @goBack="backLogin">
			<span class="title">注册信息</span>
		</navBar>

		<div class="sigin-main">
			<div class="sigin-infs">
				<div class="item">
					<span class="label">
						<span class="r">*</span>
						姓名：
					</span>
					<span class="main">
						<input type="text" placeholder="请输入姓名" v-model="userName" />
					</span>
				</div>

				<div class="item">
					<span class="label">
						<span class="r">*</span>
						手机号：
					</span>
					<span class="main">
						<input type="number" placeholder="请输入手机号码" maxlength="11" v-model="userPhone" />
					</span>
				</div>
				<div class="item">
					<span class="label">
						<span class="r">*</span>
						验证码：
					</span>
					<span class="main">
						<input type="number" placeholder="请输入手机验证码" maxlength="6" v-model="userCode" />
						<div class="send-code" :class="{ disable: isSend }" @click="sendAction">
							{{ btnText }}
						</div>
					</span>
				</div>
				<div class="item">
					<span class="label">
						<span class="r">*</span>
						密码：
					</span>
					<span class="main">
						<input type="password" placeholder="请输入密码" v-model="userPassword" />
					</span>
				</div>
				<div class="item">
					<span class="label">
						<span class="r">*</span>
						确认密码：
					</span>
					<span class="main">
						<input type="password" placeholder="请输入确认密码" v-model="userConfirmPassword" />
					</span>
				</div>
			</div>
			<div class="sigin-btn" @click="submitSigin">立即注册</div>
		</div>

		<div class="success-layer" v-if="successSigin">
			<img src="../assets/img/siginSuccess.png" class="success-img" />
			<div class="success-text">
				<h6>恭喜您，已经注册成功</h6>
				<span @click="backLogin">返回登录</span>
			</div>
		</div>
	</div>
</template>

<script>
import { checkPhone } from '@/utils/public';
export default {
	name: 'siginPage',
	data() {
		return {
			submitIng: false,
			isSend: false,
			successSigin: false,
			btnText: '发送验证码',
			userName: '',
			userPhone: '',
			userCode: '',
			userPassword: '',
			userConfirmPassword: ''
		};
	},
	methods: {
		checkPhone,
		timeDown() {
			let time = 30;
			this.btnText = `${time}s后重新发送`;
			let timer = setInterval(() => {
				if (1 < time) {
					--time;
					this.btnText = `${time}s后重新发送`;
				} else {
					time = 0;
					this.btnText = `重新发送`;
					this.isSend = false;
					clearInterval(timer);
				}
			}, 1000);
		},
		sendAction() {
			if (!this.isSend) {
				if (!this.userPhone) {
					this.$notify({
						type: 'warning',
						message: '请输入手机号'
					});
					return false;
				}
				if (!this.checkPhone(this.userPhone)) {
					this.$notify({
						type: 'warning',
						message: '请输入正确格式的手机号'
					});
					return false;
				}
				this.sendRequest();
			}
		},
		submitSigin() {
			if (!this.submitIng) {
				if (!this.userName) {
					this.$notify({
						type: 'warning',
						message: '请输入用户姓名'
					});
					return false;
				}
				if (!this.userPhone) {
					this.$notify({
						type: 'warning',
						message: '请输入手机号码'
					});
					return false;
				}
				if (!this.userCode) {
					this.$notify({
						type: 'warning',
						message: '请输入手机验证码'
					});
					return false;
				}
				if (!this.userPassword) {
					this.$notify({
						type: 'warning',
						message: '请输入密码'
					});
					return false;
				}
				if (!this.userConfirmPassword) {
					this.$notify({
						type: 'warning',
						message: '请输入确认密码'
					});
					return false;
				}
				if (this.userPassword != this.userConfirmPassword) {
					this.$notify({
						type: 'warning',
						message: '密码和确认密码不一致'
					});
					return false;
				}
				this.submitRequest();
			}
		},
		sendRequest() {
			this.isSend = true;
			this.$api
				.sendSms({
					register_mobile: this.userPhone,
					scene: 'register'
				})
				.then((res) => {
					//this.timeDown();
					let messageType = 'success';
					if (res.code == 0) {
						messageType = 'success';
						this.timeDown();
					} else {
						messageType = 'warning';
					}
					this.$notify({
						type: messageType,
						message: res.message
					});
				});
		},
		submitRequest() {
			this.submitIng = true;
			this.$toast({
				message: '正在注册...',
				type: 'loading'
			});
			this.$api
				.userSigin({
					name: this.userName,
					register_mobile: this.userPhone,
					register_mobile_code: this.userCode,
					password: this.userPassword
				})
				.then((res) => {
					this.submitIng = false;
					let messageType = 'success';
					if (res.code == 0) {
						messageType = 'success';
						this.successSigin = true;
						setTimeout(() => {
							this.backLogin();
						}, 5000);
						//this.layerStatus = true;
					} else {
						messageType = 'warning';
					}
					this.$notify({
						type: messageType,
						message: res.message
					});
				});
		},
		backLogin() {
			this.$router.push({
				path: '/login'
			});
		}
	}
};
</script>

<style lang="scss" scoped>
		.page-box.hasnav {
		    top: 0rem;
		}
.sigin-infs {
	.item {
		width: 100%;
		display: flex;
		align-items: center;
		height: 54px;
		border-bottom: 1px #dcdcdc solid;
		.label {
			max-width: 80px;
			min-width: 80px;
			font-size: 13px;
			font-weight: 400;
			color: #333;
			.r {
				color: #f00;
			}
		}
		.main {
			display: flex;
			position: relative;
			flex: 1;
			height: 54px;
			overflow: hidden;
			input {
				width: 100%;
				height: 100%;
				margin: 0;
				padding: 0;
				border: 0;
				background: none;
				font-size: 13px;
				font-weight: 400;
				color: #333;
				line-height: 54px;
			}
			.send-code {
				position: absolute;
				right: 0;
				top: 0;
				height: 28px;
				background: #ca151d;
				border-radius: 14px;
				font-size: 13px;
				font-weight: 400;
				color: #fff;
				padding: 0 10px;
				line-height: 28px;
				transform: translateY(50%);
				&:active {
					opacity: 0.8;
				}
			}
			.disable {
				background: #dcdcdc;
			}
		}
	}
}
.success-layer {
	position: fixed;
	left: 0;
	right: 0;
	top: 50px;
	bottom: 0;
	z-index: 100;
	padding: 15px;
	background: #fff;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	.success-img {
		width: 196px;
		height: 166px;
		margin-bottom: 48px;
		margin-top: 54px;
	}
	.success-text {
		display: flex;
		flex-direction: column;
		text-align: center;
		line-height: 1;
		h6 {
			font-size: 16px;
			font-weight: 500;
			color: #ca151d;
			margin-bottom: 10px;
		}
		span {
			font-size: 12px;
			font-weight: 400;
			color: #909090;
		}
	}
}
.sigin-main {
	position: fixed;
	left: 0;
	right: 0;
	top: 50px;
	bottom: 0;
	padding: 15px;
	background: #fff;
	.sigin-btn {
		position: absolute;
		left: 17px;
		right: 17px;
		bottom: 50px;
		height: 0.92rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #ca151d;
		border-radius: 0.08rem;
		font-size: 16px;
		font-weight: 500;
		color: #fff;
		&:active {
			opacity: 0.8;
		}
	}
}

</style>
