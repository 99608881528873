<template>
	<div class="page-box">
		<div @click="gokqyt" class="kqyt" v-if="UserInfs.is_mijuan == 1">考前押题卷入口</div>
		<div class="specialCourse" v-if="classData.length">
			<div class="specialCourseTitle">高三备考复习专题课</div>
			<div class="specialCourseContent">
				<div class="item" v-for="(item, index) in classData" :key="`calss${index}`" @click="goMoudle(item)">
					<van-image class="img" width="100%" fit="cover" :src="setSourceUrl(item.pcicon)" />
					<div class="text">{{ item.name }}:{{ item.teachername }}</div>
				</div>
			</div>
		</div>
		<div class="examinationXpaper" v-if="classData.length">
			<div class="examinationXpaperTitle">全科考点考能排查卷</div>
			<div class="examinationXpaperContent">
				<div class="course-list" id="scrollSuject" ref="scrollSuject">
					<ul class="list" :style="`width:${scrollXwidth}rem`">
						<li
							v-for="(item, index) in classJuanData"
							:key="`calss${index}`"
							@click="goMoudle(item, false)"
						>
							<van-image class="img" width="100%" fit="cover" :src="setSourceUrl(item.kaonengicon)" />
						</li>
					</ul>
				</div>
			</div>
		</div>
		<loadingBox class="loading2" v-else></loadingBox>
		<div class="kmLayer" v-if="isCheck">
			<div class="cententBox">
				<div class="title">请选择您要激活的科目</div>
				<div class="cententGroup">
					<div
						class="item"
						v-for="(item, index) in classData"
						:key="index + 'it'"
						@click="itemActiveFn(item)"
					>
						<div>{{ item.name }}</div>
						<div class="item-bnt" :class="{ active: item.iskey }">{{ item.iskey ? '已选择' : '选择' }}</div>
					</div>
					<div class="kmLayer-bnt" @click="kmLayerbnt">确认</div>
				</div>
			</div>
		</div>
		<div class="layer" id="layer" v-if="isLayer" @click="isLayer = false ">
			<div class="layerImg" @click.stop="gokqyt"><img :src="kqsz" alt=""></div>
		</div>
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
import LoadingBox from '@/components/loadingBox.vue';
import CoustmEmpty from '@/components/coustmEmpty.vue';
import BetterScroll from 'better-scroll';
import ScrollBar from '@better-scroll/scroll-bar';
BetterScroll.use(ScrollBar);
export default {
	name: 'mainPage',
	components: {
		LoadingBox,
		CoustmEmpty
	},
	computed: {
		scrollXwidth: function () {
			// return 2.38 * this.classData.length;
		}
	},
	watch: {},
	data() {
		return {
			classData: [],
			checkList: [],
			classJuanData: [],
			isCheck: true,
			isLayer: false,
			kqsz: require('@/assets/image/kqsz.png'),
			UserInfs: this.$store.getters['userCenter/getUserInfs']
		};
	},
	async created() {
		this.isCheck = this.UserInfs.select_subject == 0 ? true : false;
		this.isLayer = this.UserInfs.is_mijuan == 1 ? true : false;
		await this.initClass();
	},
	mounted() {
		window.sessionStorage.clear();
		this.$nextTick(() => {
			// this.initBscroll();
			if(this.isLayer){
				this.appendDomToRoot(document.querySelector("#isLayer"), "#app");
			}
		});
		
	},
	methods: {
		setSourceUrl,
		// initClass() {
		// 	this.isLoadingSuject = true;
		// 	this.$api
		// 		.getSubject({
		// 			role_id: 2
		// 		})
		// 		.then((res) => {
		// 			let resData = res.data || [];
		// 			resData.map((item) => {
		// 				item.iskey = false;
		// 			});
		// 			this.classData = resData;

		// 			this.isLoadingSuject = false;
		// 			this.$nextTick(() => {
		// 				// this.initBscroll();
		// 			});
		// 		});
		// },
		appendDomToRoot(el, selector) {
		    //el 为要操作的dom元素，selector为要插入的根节点的选择器，为空的话，直接插入到body中 
		      var container;
		      if (selector) {
		        container = document.querySelector(selector);
		      }
		      if (!container) {
		        container = document.body;
		      }
		      container.appendChild(el);
		    },
		initClass() {
			this.isLoadingSuject = true;
			this.$api
				.getSubject({
					role_id: 2
				})
				.then((res) => {
					let resData = res.data || [];
					let pD = ['语文', '数学', '英语', '物理', '化学', '生物', '历史', '地理', '政治'];
					let newData = [];
					let oneData = [];
					pD.forEach((item) => {
						resData.forEach((list) => {
							if (item == list.name) {
								list.iskey = false;
								list.isZT = false;
								newData.push(list);
								oneData.push(list);
							}
						});
					});
					this.classData = newData;
					let writing = resData[resData.length - 1].writing;
					pD.forEach((item) => {
						writing.forEach((list) => {
							if (item == list.name) {
								list.iskey = false;
								list.isZT = true;
								list.kaonengicon = list.writingicon;
								oneData.push(list);
							}
						});
					});

					this.classJuanData = oneData;
					console.log(this.classJuanData);
					// resData.map((item) => {
					// 	item.iskey = false;
					// });
					// this.classData = resData;

					this.isLoadingSuject = false;
					this.$nextTick(() => {
						// this.initBscroll();
					});
				});
		},

		initBscroll() {
			let that = this;
			that.scrollSubject = new BetterScroll(that.$refs['scrollSuject'], {
				scrollX: true,
				scrollY: false,
				click: true,
				mouseWheel: true,
				bounce: false,
				swipeBounceTime: 0,
				bounceTime: 0,
				preventDefault: true
			});
		},
		swiperDetail(url, title) {
			if (url) {
				this.$router.push({
					path: '/webView',
					query: {
						webUrl: url,
						title: title
					}
				});
			}
		},
		gokqyt() {
			this.$router.push({
				path: '/kqyt'
				// query: {
				// 	webUrl: url,
				// 	title: title
				// }
			});
		},
		notifyDetail(id) {
			this.$router.push({
				path: '/notifyDetail',
				query: {
					id: id
				}
			});
		},
		// goMoudle(item, type = true) {
		// 	let path = type ? '/specialSubject' : '/examinationpaper';
		// 	this.$router.push({
		// 		query: {
		// 			from: '',
		// 			subject_id: item.id,
		// 			name: item.name
		// 		},
		// 		path: path
		// 	});
		// },
		goMoudle(item, type = true) {
			let path = type ? '/specialSubject' : '/examinationpaper';
			if (item.isZT) {
				this.$router.push({
					query: {
						coursetypeid: item.id,
						papeid: item.papeid
					},
					path: '/zhenTi'
				});
			} else {
				this.$router.push({
					query: {
						from: '',
						subject_id: item.id,
						name: item.name
					},
					path: path
				});
			}
		},
		itemActiveFn(item) {
			item.iskey = !item.iskey;
			let isIdData = [];
			this.classData.forEach((item) => {
				if (item.iskey) {
					isIdData.push(item.id);
				}
			});
			this.checkList = isIdData;
		},
		setNum(num) {
			num = Number(num);
			let numData = ['一', '二', '三', '四', '五', '六', '七', '八', '九'];
			return numData[num - 1];
		},
		kmLayerbnt() {
			if (this.checkList.length) {
				if (this.UserInfs.maxsubjectnum > this.checkList.length) {
					this.$toast(`您总共可激活${this.setNum(this.UserInfs.maxsubjectnum)}科！`);
				}
				if (this.UserInfs.maxsubjectnum < this.checkList.length) {
					this.$toast(`您所选科目已超过可激活科目`);
				}
				if (this.UserInfs.maxsubjectnum == this.checkList.length) {
					this.$api
						.addusersubjects({
							subjectid: this.checkList.join(',')
						})
						.then((res) => {
							if (res.code == 0) {
								this.UserInfs.select_subject = 1;
								// this.isCheck = false;
								this.$store.commit('common/setUserInfs', this.UserInfs);
								this.$toast(res.message);
								this.$router.go(0);
							}
						});
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.page-box {
	width: 100%;
	height: 100%;
	padding: 0 0.24rem 0.8rem;
	.kqyt {
		text-align: center;
		width: 100%;
		font-size: 0.36rem;
		padding: 0.12rem 0.3rem;
		background-color: #05549a;
		color: #fff;
		border-radius: 0.5rem;
		margin-top: 0.25rem;
	}
}
.kmLayer {
	height: 100vh;
	width: 100vw;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1001;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.28rem;
	.cententBox {
		width: 100%;
		min-height: 100%;
		background-color: #fff;
		.cententGroup {
			padding: 0.2rem 0.3rem;
			.item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 0.8rem;
				border-bottom: 1px solid #dcdcdc;
				&:last-child {
					border-bottom: 0 none;
				}
				.item-bnt {
					background-color: #d0e9ff;
					border-radius: 0.4rem;
					color: #05549a;
					padding: 0.1rem 0.2rem;
					&.active {
						background-color: #05549a;
						color: #fff;
					}
				}
			}
		}
		.title {
			line-height: 1.08rem;
			text-align: center;
			font-size: 0.32rem;
		}
		.kmLayer-bnt {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 0.8rem;
			background-color: #05549a;
			color: #fff;
			border-radius: 0.4rem;
			text-align: center;
			margin-top: 1.5rem;
		}
	}
}
.cententBox {
	::v-deep .el-checkbox__label {
		font-size: 0.2rem;
	}
	::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
		color: #05549a;
	}
	::v-deep .el-checkbox {
		font-size: 0.3rem;
		margin-right: 0;
	}
	::v-deep .el-checkbox__inner {
		width: 0.2rem;
		height: 0.2rem;
		&::after {
			width: 0.08rem;
			height: 0.12rem;
		}
	}
	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #05549a;
		border-color: #05549a;
	}
}

.specialCourseTitle,
.examinationXpaperTitle {
	font-size: 0.34rem;
	font-weight: bold;
	margin: 0.4rem 0;
}
.specialCourseContent {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.item {
		width: calc(50% - 0.12rem);
		display: flex;
		flex-direction: column;
		.text {
			font-size: 0.26rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #1d1d1d;
			margin: 0.2rem 0;
		}
	}
}
.course-list {
	position: relative;
	width: 100%;
	margin-bottom: 0.35rem;
	overflow: hidden;
	.list {
		display: flex;
		// justify-content: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
		li {
			width: 2.14rem;
			min-width: 2.14rem;
			// margin-right: .24rem;
			cursor: pointer;
		}
	}
}
.layer{
	width: 100vw;
	height: 100vh;
	top:0;
	left:0;
	position: fixed;
	z-index: 1111;
	background-color: rgba(0, 0, 0, 0.5);
	.layerImg{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 0.6rem
	}
	display: flex;
	justify-content: center;
	align-items: center;
	img{
		width: 70%;
		height: 70%;
	};
}
</style>
