<template>
	<div class="page-box">
		<div class="nav-bar">
			<div class="center">
				<slot>{{ title }}</slot>
			</div>
			<div class="right">
				<span
					style="color: #05549a; font-size: 0.4rem"
					class="menu-icon iconfont icon-mulu"
					@click="show = true"
				></span>
			</div>
		</div>
		<div class="videosBox" ref="videosBox">
			<loadingBox v-if="isLoading"></loadingBox>
			<!-- <div v-html="dataList"></div> -->
			<div v-if="dataList.length > 0">
				<div
					class="img"
					:key="index + 'img'"
					v-for="(a, index) in dataList"
					:src="a"
					alt=""
					@click="open(index)"
				>
					<img style="" :src="a" alt="" />
				</div>
			</div>
			<div class="paly" v-if="isPaly">该科目暂未激活</div>
		</div>
		<div class="bntText">
			<div @click="setAnswer()">{{ bntText }}</div>
			<div class="btn" v-if="$route.query.name == '英语'" @click="playAudio">
				{{ tingliPlaying ? '暂停' : '播放' }}
			</div>
		</div>
		<van-popup
			v-model="show"
			position="right"
			:style="{ width: '80%', height: '100%' }"
			:lock-scroll="false"
			closeable
		>
			<div class="pageBox">
				<div class="title">{{ title }}</div>
				<div class="content">
					<van-cell-group>
						<van-field
							readonly
							is-link
							v-model="fromData.province"
							label="省份"
							placeholder="请选择省份"
							@click="pickerTypeFn('province')"
						/>
						<van-field
							readonly
							is-link
							v-model="fromData.year"
							label="年份"
							placeholder="请选择年份"
							@click="pickerTypeFn('year')"
						/>
						<van-field
							readonly
							is-link
							v-model="fromData.city"
							label="城市"
							placeholder="请选择城市"
							@click="pickerTypeFn('city')"
						/>
					</van-cell-group>
					<van-popup
						v-model="showpicker"
						position="bottom"
						:style="{ width: '100%', height: '50%' }"
						:lock-scroll="false"
					>
						<div class="pageBox">
							<van-picker
								show-toolbar
								v-if=""
								:columns="aertData"
								@cancel="onCancel"
								@confirm="onConfirm"
								:overlay="false"
							/>
						</div>
					</van-popup>
				</div>
				<div class="bnt" @click="showMenu">查看试卷</div>
			</div>
			<!-- <popupMenu :menuData="menuData" :isTypeExamin="false" :title="documentTitle" bntText="查看试卷"></popupMenu> -->
		</van-popup>
		<audio
			:src="setSourceUrl(tingliAudio)"
			controlslist="nodownload"
			controls="controls"
			ref="tingliPlayer"
			class="status-vioce"
			@loadedmetadata="loadedmetadata"
			@ended="audioEnd"
		></audio>
		<van-image-preview
			v-model="showimage"
			:startPosition="indeximage - 1"
			:images="dataList"
			@change="onChange"
			v-if="dataList.length"
		>
			<template v-slot:indeximage>第{{ indeximage }}页</template>
		</van-image-preview>
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
import city from '@/utils/city.json';
import LoadingBox from '@/components/loadingBox.vue';
import CoustmEmpty from '@/components/coustmEmpty.vue';
import popupMenu from './components/popupMenu.vue';
import BetterScroll from 'better-scroll';
import ScrollBar from '@better-scroll/scroll-bar';
BetterScroll.use(ScrollBar);
export default {
	name: 'specialSubject',
	components: {
		LoadingBox,
		CoustmEmpty,
		popupMenu,
		vidoeList2: () => import('./components/videoList2.vue')
	},
	computed: {},
	watch: {},
	data() {
		return {
			menuData: [],
			show: true,
			showpicker: false,
			showimage: false,
			indeximage: 0,
			images: [],
			isLoading: false,
			title: '请选择中考真题卷',
			menuActive: {},
			titleC: '',
			documentTitle: this.$route.query.name + '中考真题卷' || '',
			dataList: '',
			bntText: '查看答案',
			tingliAudio: '',
			tingliPlayer: null,
			isPlaying: false,
			tingliPlaying: false,
			isCanplay: false,
			isPaly: false,
			pickerType: null,
			cityData: [],
			province: [
				{ text: '北京市', selected: false, value: 1, code: '110000' },
				{ text: '天津市', selected: false, value: 2, code: '120000' },
				{ text: '上海市', selected: false, value: 3, code: '310000' },
				{ text: '重庆市', selected: false, value: 4, code: '500000' },
				{ text: '河北省', selected: false, value: 5, code: '130000' },
				{ text: '河南省', selected: false, value: 6, code: '410000' },
				{ text: '云南省', selected: false, value: 7, code: '530000' },
				{ text: '辽宁省', selected: false, value: 8, code: '210000' },
				{ text: '黑龙江省', selected: false, value: 9, code: '230000' },
				{ text: '湖南省', selected: false, value: 10, code: '430000' },
				{ text: '安徽省', selected: false, value: 11, code: '340000' },
				{ text: '山东省', selected: false, value: 12, code: '370000' },
				{
					text: '新疆维吾尔自治区',
					selected: false,
					value: 13,
					code: '650000'
				},
				{ text: '江苏省', selected: false, value: 14, code: '320000' },
				{ text: '浙江省', selected: false, value: 15, code: '330000' },
				{ text: '江西省', selected: false, value: 16, code: '360000' },
				{ text: '湖北省', selected: false, value: 17, code: '420000' },
				{ text: '广西壮族自治区', selected: false, value: 18, code: '450000' },
				{ text: '甘肃省', selected: false, value: 19, code: '620000' },
				{ text: '山西省', selected: false, value: 20, code: '140000' },
				{ text: '内蒙古自治区', selected: false, value: 21, code: '150000' },
				{ text: '陕西省', selected: false, value: 22, code: '610000' },
				{ text: '吉林省', selected: false, value: 23, code: '220000' },
				{ text: '福建省', selected: false, value: 24, code: '350000' },
				{ text: '贵州省', selected: false, value: 25, code: '520000' },
				{ text: '广东省', selected: false, value: 26, code: '440000' },
				{ text: '青海省', selected: false, value: 27, code: '630000' },
				{ text: '西藏自治区', selected: false, value: 28, code: '540000' },
				{ text: '四川省', selected: false, value: 29, code: '510000' },
				{ text: '宁夏回族自治区', selected: false, value: 30, code: '640000' },
				{ text: '海南省', selected: false, value: 31, code: '460000' },
				{ text: '台湾省', selected: false, value: 32, code: '710000' }
			],
			yaerData: [],
			aertData: [],
			fromData: {
				year: null,
				papeid: this.$route.query.papeid,
				type: null,
				province: null,
				provinceCode: null,
				provinceIndex: null,
				city: null,
				provincecity: null
			},
			zhenTiData: []
		};
	},

	async created() {
		window.document.title = '历年中考真题卷';
	},
	mounted() {
		this.$eventBus.$on('menuActive', (val) => {
			this.menuActive = val;
			this.titleC = val.name;
		});
		this.$eventBus.$on('getShowMenu', (val) => {
			this.show = false;
			this.showMenu();
			this.title = this.titleC;
			this.$refs.videosBox.scrollTop = 0;
		});
		this.tingliPlayer = this.$refs['tingliPlayer'];
	},
	methods: {
		setSourceUrl,
		pickerTypeFn(type) {
			console.log(type);
			if (type == 'province') {
				this.aertData = this.province;
			} else if (type == 'year') {
				if (this.yaerData.length) {
					this.aertData = this.yaerData;
				} else {
					this.$notify({
						type: 'warning',
						message: '请先选择省份'
					});
					return false;
				}
			} else if (type == 'city') {
				console.log(this.cityData,'this.cityDatathis.cityData')
				if (this.cityData.length) {
					this.aertData = this.cityData;
				} else {
					this.$notify({
						type: 'warning',
						message: '请先选择年份'
					});
					return false;
				}
			}
			this.pickerType = type;
			this.showpicker = true;
		},
		onChange(index) {
			this.indeximage = index + 1;
		},
		onConfirm(value, index) {
			this.fromData[this.pickerType] = value.text;
			if (this.pickerType == 'province') {
				// this.getYearData()
				this.fromData.provinceCode = value.code;
				this.selectYearFun();
				this.title = value.text + '高考真题卷';
			} else if (this.pickerType == 'year') {
				// this.getCityData()
				this.fromData.provinceIndex = index;
				let item = this.zhenTiData[index].provincename;
				let _cityData = [{
					text: item,
					value: item
				}];
				this.cityData = _cityData;
				console.log(this.cityData)
			}
			this.showpicker = false;
		},
		onCancel() {
			this.showpicker = false;
		},
		getCityData() {
			this.$api
				.getPaperCitys({
					province: this.fromData.province,
					coursetypeid: this.$route.query.coursetypeid,
					year: this.fromData.year
				})
				.then((res) => {
					let year = res.data || [];
					let _cityData = [];
					year.forEach((item) => {
						_cityData.push({
							text: item,
							value: item
						});
					});
					this.cityData = _cityData;
				});
		},

		getYearData() {
			this.$api
				.getPaperYear({
					province: this.fromData.province,
					coursetypeid: this.$route.query.coursetypeid
				})
				.then((res) => {
					let year = res.data || [];
					let _timeData = [];
					year.forEach((item) => {
						_timeData.push({
							text: item,
							value: item
						});
					});
					this.yaerData = _timeData;
				});
		},
		selectYearFun(str) {
			this.selectYear = str;
			this.loading = true;
			this.showCity = false;
			this.cityData = [];
		
			this.fromData.year = null;
			this.fromData.provinceIndex = 0;
			this.fromData.city = null;
			this.$api
				.getZhenTiGz({
					province: this.fromData.provinceCode,
					subject_id: this.$route.query.coursetypeid,
					year: str,

					role_id: 2
				})
				.then((res) => {
					this.loading = false;
					let resData = res.data;
					this.zhenTiData = resData;
					if (resData.length) {
						this.yaerData = [];
						let _timeData = [];
						resData.forEach((item) => {
							_timeData.push({
								text: item.year,
								value: item.year
							});
						});
						this.yaerData = _timeData;
					} else {
						this.$customMessage('暂无数据');
					}
				});
		},
		open(data) {
			let that = this;
			// console.log(that.dataList[data], '--------');
			// this.images = that.dataList[data];
			that.indeximage = data + 1;
			that.showimage = true;
		},

		showMenu(resData) {
			// this.isLoading = true;
			// this.$api
			// 	.getYearPape({
			// 		year: this.fromData.year,
			// 		papeid: this.fromData.papeid,
			// 		type: 0,
			// 		city: `${this.fromData.province}/${this.fromData.city}`
			// 	})
			// 	.then((res) => {
			// 		this.menuActive = res.data;
			// 		if (res.code == 1) {
			// 			this.isPaly = true;
			// 		}
			// 		this.arrDataFliert(this.menuActive.papecontent, this.dataList);
			// 		this.tingliAudio = this.menuActive.audiofile || '';
			// 		this.isLoading = false;
			// 		this.show = false;
			// 	});
			console.log(this.fromData.provinceIndex )
			let menuActive = this.zhenTiData[this.fromData.provinceIndex]
			this.arrDataFliert(menuActive.testquestions, this.dataList);
			this.tingliAudio = menuActive.audiofile || '';
			this.show = false;
		},
		arrDataFliert(str, Data) {
			if (str) {
				var imgReg = /<img.*?(?:>|\/>)/gi;
				var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
				var arr = str.match(imgReg); // arr 为包含所有img标签的数组
				var arrD = [];
				for (var i = 0; i < arr.length; i++) {
					var src = arr[i].match(srcReg);
					//获取图片地址
					arrD.push(src[1]);
				}
				this.dataList = arrD;
			}
		},
		setAnswer(type) {
			let menuActive = this.zhenTiData[this.fromData.provinceIndex]
			let arrD = this.bntText == '查看答案' ? menuActive.answer : menuActive.testquestions;
			this.arrDataFliert(arrD, this.dataList);
			this.bntText = this.bntText == '查看答案' ? '回到试题' : '查看答案';
		},
		audioEnd() {
			this.tingliPlaying = false;
		},
		loadedmetadata() {
			this.tingliPlaying = false;
			this.isCanplay = true;
		},
		playAudio() {
			if (!this.isCanplay) {
				this.$notify({
					type: 'warning',
					message: '音频加载中...'
				});
				return false;
			}

			if (this.tingliPlaying) {
				this.tingliPlayer.pause();
			} else {
				this.tingliPlayer.play();
			}
			this.tingliPlaying = !this.tingliPlaying;
		}
	}
};
</script>
<style>
.van-image img {
	pointer-events: none !important;
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	user-select: none !important;
}
</style>
<style lang="scss" scoped>
.page-box {
	width: 100%;
	height: 100%;
	padding: 0 0.24rem;
	position: relative;
}
.nav-bar {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 0.88rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.24rem;
	box-shadow: 0px 0px 10px 0px rgb(189 189 189 / 50%);
	z-index: 10;
	.center {
		font-size: 0.32rem;
	}
	.right {
		display: flex;
		align-items: center;
	}
}
.videosBox {
	margin-top: 0.88rem;
	height: calc(100% - 2.2rem);
	padding-top: 0.4rem;
	overflow: auto;
	position: relative;
	img {
		height: auto;
	}
}
.bntText {
	width: 100%;
	padding: 0 0.24rem;
	height: 0.76rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0.24rem 0;
	font-size: 0.28rem;
	color: #fff;
	& > div {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #05549a;
		border-radius: 0.36rem;
		height: 0.76rem;
	}
	.btn {
		margin-left: 0.2rem;
	}
}
.paly {
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 0.28rem;
	justify-content: center;
}
.pageBox {
	width: 100%;
	height: 100%;
	padding: 0 0rem;
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	.title {
		height: 1rem;
		line-height: 1rem;
		font-size: 0.32rem;
		padding: 0 0.24rem;
	}
	.content {
		flex: 1;
		overflow: auto;
	}
	.bnt {
		height: 0.8rem;
		font-size: 0.32rem;
		width: calc(100% - 0.48rem);
		background-color: #05549a;
		border-radius: 0.4rem;

		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0.24rem;
		color: #fff;
	}
}
</style>
