<template>
	<div class="page-login">
		<div class="banner">
			<van-image :src="loginBanner" fit="cover"></van-image>
			
		</div>
		<ul class="login-tab">
			<li
				v-for="(tab, index) in tabs"
				:key="index"
				:class="{ active: currentTag == tab.tag }"
				@click="currentTag = tab.tag"
			>
				{{ tab.tit }}
			</li>
		</ul>
		<div class="login-box">
			<template v-if="currentTag == 1">
				<div class="item">
					<!-- <div class="icon">
						<van-icon name="contact" style="font-size: 21px" />
					</div> -->
					<van-field v-model="account" :clearable="true" placeholder="请输入您的账号" />
				</div>
				<div class="item">
				<!-- 	<div class="icon">
						<i class="iconfont icon-mima"></i>
					</div> -->
					<van-field v-model="password" type="password" :clearable="true" placeholder="请输入您的密码" />
				</div>
				<!-- <div class="password-status">
<div class="remember">
<van-checkbox
shape="square"
v-model="rememberPassword"
checked-color="#ca151d"
></van-checkbox>
记住密码
</div>
<div class="forgot">忘记密码</div>
</div> -->
			</template>

			<template v-if="currentTag == 2">
				<div class="item">
					<!-- <div class="icon">
						<i class="iconfont icon-shoujihao" style="font-size: 18px"></i>
					</div> -->
					<van-field v-model="userPhone" type="tel" :clearable="true" placeholder="请输入您的手机号" />
				</div>
				<div class="item">
					<!-- <div class="icon">
						<i class="iconfont icon-mima"></i>
					</div> -->
					<van-field v-model="userCode" type="digit" :clearable="true" placeholder="请输入验证码" />
					<div class="send-code" :class="{ disable: isSend }" @click="sendAction">
						{{ btnText }}
					</div>
				</div>
			</template>
			<div class="submit-btn" v-if="currentTag == 1" @click="onSubmit">登录</div>
			<div class="submit-btn" v-if="currentTag == 2" @click="loginByPhone">登录</div>
			<div class="sigin-notify">
				还没有账号，
				<span @click="goSigin" style="color: #ca151d;">立即注册？</span>
			</div>
		</div>
	</div>
</template>

<script>
import { setToken } from '@/utils/token';
import { checkPhone } from '@/utils/public';
export default {
	name: 'loginPage',
	data() {
		return {
			loginBanner: require('../assets/img/loginBanner.png'),
			tabs: [
				{
					tag: 1,
					tit: '密码登录'
				},
				{
					tag: 2,
					tit: '验证码登录'
				}
			],
			currentTag: 1,
			rememberPassword: false,
			isSend: false,
			account: '',
			password: '',
			userPhone: '',
			userCode: '',
			btnText: '发送验证码'
		};
	},
	mounted() {},
	methods: {
		setToken,
		checkPhone,
		timeDown() {
			let time = 30;
			this.btnText = `${time}s后重新发送`;
			let timer = setInterval(() => {
				if (1 < time) {
					--time;
					this.btnText = `${time}s后重新发送`;
				} else {
					time = 0;
					this.btnText = `重新发送`;
					this.isSend = false;
					clearInterval(timer);
				}
			}, 1000);
		},
		goSigin() {
			this.$router.push({
				path: '/sigin'
			});
		},

		sendAction() {
			if (!this.isSend) {
				if (!this.userPhone) {
					this.$notify({
						type: 'warning',
						message: '请输入手机号'
					});
					return false;
				}
				if (!this.checkPhone(this.userPhone)) {
					this.$notify({
						type: 'warning',
						message: '请输入正确格式的手机号'
					});
					return false;
				}
				this.sendRequest();
			}
		},

		sendRequest() {
			this.isSend = true;
			this.$api
				.sendSms({
					register_mobile: this.userPhone,
					scene: 'login'
				})
				.then((res) => {
					//this.timeDown();
					let messageType = 'success';
					if (res.code == 0) {
						messageType = 'success';
						this.timeDown();
					} else {
						this.isSend = false;
						messageType = 'warning';
					}
					this.$notify({
						type: messageType,
						message: res.message
					});
				});
		},

		loginByPhone() {
			if (!this.userPhone) {
				this.$notify({
					type: 'warning',
					message: '请输入您的手机号码'
				});
				return false;
			}
			if (!this.userCode) {
				this.$notify({
					type: 'warning',
					message: '请输入手机验证码'
				});
				return false;
			}
			this.isLogin = true;
			this.$toast({
				message: '正在登录...',
				type: 'loading'
			});
			this.$api
				.userLoginPhone({
					register_mobile: this.userPhone,
					register_mobile_code: this.userCode
				})
				.then((res) => {
					this.isLogin = false;
					if (res.code == 0) {
						this.$notify('登录成功', 'success');
						this.loginSuccess(res.data);
					} else {
						this.$notify(res.message, 'error');
					}
				});
		},
		onSubmit() {
			if (!this.account) {
				this.$notify({
					type: 'warning',
					message: '请输入账号！'
				});
				return;
			}
			if (!this.password) {
				this.$notify({
					type: 'warning',
					message: '请输入密码！'
				});
				return;
			}
			this.$toast({
				message: '正在登录...',
				type: 'loading'
			});
			this.$api
				.userLoginPassword({
					name: this.account,
					password: this.password
				})
				.then((res) => {
					if (res.code == 0) {
						this.$notify({ type: 'success', message: res.message });
						this.loginSuccess(res.data);
					} else {
						this.$notify({ type: 'warning', message: res.message });
					}
				});
		},

		loginSuccess(resData) {
			window.localStorage.setItem('token', resData.token);

			this.$api.userBaseInfos().then((res) => {
				if (res.code == 0) {
					this.$store.commit('userCenter/setUserInfs', res.data);
					window.localStorage.setItem('complatedStatus', res.data.is_infos);
					window.localStorage.setItem('isActivation', res.data.is_active);
					if (res.data.is_active == 0) {
						this.$router.replace({
							path: '/complateInfs'
						});
					}else if (res.data.is_infos == 1) {
						window.location.href = '#/?active=0';
					} else {
						this.$router.replace({
							path: '/complateInfs'
						});
					}
				}
			});
		}

		// getWebInfs() {
		// this.$api.webInfs().then((res) => {
		// this.$store.commit("common/setWebInfs", res.data);
		// this.$store.commit("common/setSiteurl", res.data.siteurl);

		// this.$router.replace({
		// path: "/",
		// query: {
		// active: 0,
		// },
		// });
		// });
		// },
	}
};
</script>

<style lang="scss" scoped>
.text-input {
	font-size: 0.6rem;
	::v-deep input {
		font-size: 0.6rem;
	}
}
.page-login {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	background: #fff;
}
.sigin-notify {
	padding-top: 0.32rem;
	text-align: center;
	font-weight: 400;
	color: #909090;
	font-size: 0.3rem;
}
.password-status {
	padding: 0 0.36rem;
	display: flex;
	justify-content: space-between;
	color: #909090;
	font-weight: 400;
	.remember {
		display: flex;
		align-items: center;
		::v-deep .van-checkbox {
			margin-right: 0.1rem;
			transform: scale(0.8);
			transform-origin: center;
			.van-icon {
				border-radius: 0.1rem;
			}
		}
	}
}
.login-tab {
	// position: absolute;
	// left: 0;
	// right: 0;
	// bottom: 0.34rem;
	margin-top: 0.28rem;
	z-index: 10;
	display: flex;
	justify-content: space-around;
	align-items: center;
	li {
		font-size: 0.3rem;
		font-weight: 400;
		// color: #fff;
	}
	.active {
		position: relative;
		color:#ca151d;
		// &::after {
		// 	position: absolute;
		// 	left: 50%;
		// 	bottom: 0;
		// 	transform: translate(-50%, 130%);
		// 	content: '';
		// 	width: 0;
		// 	height: 0;
		// 	border: 0.14rem rgba(255, 255, 255, 0) solid;
		// 	border-bottom-color: #ca151d;
		// }
	}
}
.login-box {
	width: 100%;
	padding: 0.9rem 0.4rem 0 0.4rem;
	.item {
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 0.56rem;
		border-bottom: 1px solid #dcdcdc;
		overflow: hidden;
		.icon {
			display: flex;
			justify-content: center;
			width: 1rem;
			color: #909090;
			i {
				font-size: 0.48rem;
				color: #ca151d;
			}
		}
		.send-code {
			position: absolute;
			right: 0rem;
			top: 0.13rem;
			height: 0.48rem;
			border-radius: 0.24rem;
			padding: 0 0.2rem;
			background: #ca151d;
			color: #fff;
			font-size: 0.24rem;
			display: flex;
			align-items: center;
		}
		.disable {
			background: #dcdcdc;
		}
	}
	.submit-btn {
		margin-top: 1.44rem;
		text-align: center;
		line-height: 0.92rem;
		height: 0.92rem;
		background: #ca151d;
		// box-shadow: 0px 3px 7px 0px rgba(5 ,84 ,154, 0.35);
		border-radius: 0.08rem;
		font-size: 0.3rem;
		font-weight: 500;
		color: #fff;
		&:active {
			opacity: 0.8;
		}
	}
}
.banner {
	position: relative;
	width: 100%;
	// margin-bottom: 0.97rem;
	::v-deep .van-image {
		display: block;
	}
}
</style>
