<template>
	<div class="page-box">
		<div class="nav-bar " :class="{'centerKayt':iskayt == 1}">
			<div class="center" >
				<slot>{{ title }}</slot>
			</div>
			<div class="right" v-if="iskayt ==0">
				<span
					style="color: #05549a; font-size: 0.4rem"
					class="menu-icon iconfont icon-mulu"
					@click="show = true"
				></span>
			</div>
		</div>
		<div class="videosBox" ref="videosBox">
			<loadingBox v-if="isLoading"></loadingBox>
			<!-- <div v-html="dataList"></div> -->
			<div v-if="dataList.length > 0">
				<div
					class="img"
					:key="index + 'img'"
					v-for="(a, index) in dataList"
					:src="a"
					alt=""
					@click="open(index)"
				>
					<img style="" :src="a" alt="" />
				</div>
			</div>
			<div class="paly" v-if="isPaly">该科目暂未激活</div>
		</div>
		<div class="bntText">
			<div @click="setAnswer()">{{ bntText }}</div>
			<div class="btn" v-if="$route.query.name == '英语'" @click="playAudio">
				{{ tingliPlaying ? '暂停' : '播放' }}
			</div>
		</div>
		<van-popup
			v-model="show"
			position="right"
			:style="{ width: '80%', height: '100%' }"
			:lock-scroll="false"
			closeable
		>
			<popupMenu :menuData="menuData" :isTypeExamin="false" :title="documentTitle" bntText="查看试卷"></popupMenu>
		</van-popup>
		<audio
			:src="setSourceUrl(tingliAudio)"
			controlslist="nodownload"
			controls="controls"
			ref="tingliPlayer"
			class="status-vioce"
			@loadedmetadata="loadedmetadata"
			@ended="audioEnd"
		></audio>
		<van-image-preview
			v-model="showimage"
			:startPosition="indeximage - 1"
			:images="dataList"
			@change="onChange"
			v-if="dataList.length"
		>
			<template v-slot:indeximage>第{{ indeximage }}页</template>
		</van-image-preview>
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
import LoadingBox from '@/components/loadingBox.vue';
import CoustmEmpty from '@/components/coustmEmpty.vue';
import popupMenu from './components/popupMenu.vue';
import BetterScroll from 'better-scroll';
import ScrollBar from '@better-scroll/scroll-bar';
BetterScroll.use(ScrollBar);
export default {
	name: 'specialSubject',
	components: {
		LoadingBox,
		CoustmEmpty,
		popupMenu,
		vidoeList2: () => import('./components/videoList2.vue')
	},
	computed: {},
	watch: {},
	data() {
		return {
			menuData: [],
			show: false,
			showimage: false,
			indeximage: 0,
			images: [],
			isLoading: false,
			title: '请选择试卷',
			menuActive: {},
			titleC: '',
			documentTitle: this.$route.query.name + '考能卷列表' || '',
			iskayt: this.$route.query.iskayt ||0,
			dataList: '',
			bntText: '查看答案',
			tingliAudio: '',
			tingliPlayer: null,
			isPlaying: false,
			tingliPlaying: false,
			isCanplay: false,
			isPaly: false
		};
	},

	async created() {
		window.document.title = this.iskayt==0? this.$route.query.name + '考点考能排查卷':this.$route.query.name;
		await this.initClass();
	},
	mounted() {
		this.$eventBus.$on('menuActive', (val) => {
			this.menuActive = val;
			this.titleC =  this.iskayt==0?  val.name:this.$route.query.name;
		});
		this.$eventBus.$on('getShowMenu', (val) => {
			this.show = false;
			this.showMenu();
			this.title = this.titleC;
			this.$refs.videosBox.scrollTop = 0;
		});
		this.tingliPlayer = this.$refs['tingliPlayer'];
	},
	methods: {
		setSourceUrl,
		onChange(index) {
			this.indeximage = index + 1;
		},
		open(data) {
			let that = this;
			// console.log(that.dataList[data], '--------');
			// this.images = that.dataList[data];
			that.indeximage = data + 1;
			that.showimage = true;
		},
		initClass() {
			this.$api
				.getZhenTi({
					role_id: 2,
					subject_id: this.$route.query.subject_id,
					type:this.$route.query.type
				})
				.then((res) => {
					let resData = res.data || [];
					if (res.code == 1) {
						this.isPaly = true;
					}
					resData.forEach((item, index) => {
						let idx = 0;
						item.index = idx;
						if (index == 0) {
							item.showChild = true;
						} else {
							item.showChild = false;
						}
					});
					this.menuData = resData;
					if (resData.length == 0) {
						return false;
					}
					this.menuActive = resData[0];
					this.title = this.iskayt==0? resData[0].name:this.$route.query.name;
					this.showMenu();
				});
		},
		showMenu(resData) {
			this.isLoading = true;
			// this.dataList = this.menuActive.testquestions;
			this.arrDataFliert(this.menuActive.testquestions, this.dataList);
			this.tingliAudio = this.menuActive.audiofile;
			this.isLoading = false;
		},
		arrDataFliert(str, Data) {
			if (str) {
				var imgReg = /<img.*?(?:>|\/>)/gi;
				var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
				var arr = str.match(imgReg); // arr 为包含所有img标签的数组
				var arrD = [];
				for (var i = 0; i < arr.length; i++) {
					var src = arr[i].match(srcReg);
					//获取图片地址
					arrD.push(src[1]);
				}
				this.dataList = arrD;
			}
		},
		setAnswer(type) {
			let arrD = this.bntText == '查看答案' ? this.menuActive.answer : this.menuActive.testquestions;
			this.arrDataFliert(arrD, this.dataList);
			this.bntText = this.bntText == '查看答案' ? '回到试题' : '查看答案';
		},
		audioEnd() {
			this.tingliPlaying = false;
		},
		loadedmetadata() {
			this.tingliPlaying = false;
			this.isCanplay = true;
		},
		playAudio() {
			if (!this.isCanplay) {
				this.$notify({
					type: 'warning',
					message: '音频加载中...'
				});
				return false;
			}

			if (this.tingliPlaying) {
				this.tingliPlayer.pause();
			} else {
				this.tingliPlayer.play();
			}
			this.tingliPlaying = !this.tingliPlaying;
		}
	}
};
</script>
<style>
.van-image img {
	pointer-events: none !important;
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	user-select: none !important;
}
</style>
<style lang="scss" scoped>
.page-box {
	width: 100%;
	height: 100%;
	padding: 0 0.24rem;
	position: relative;
}
.nav-bar {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 0.88rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.24rem;
	box-shadow: 0px 0px 10px 0px rgb(189 189 189 / 50%);
	z-index: 10;
	.center {
		font-size: 0.32rem;
	}
	
	.right {
		display: flex;
		align-items: center;
	}
	&.centerKayt{
		justify-content: center;
	}
}
.videosBox {
	margin-top: 0.88rem;
	height: calc(100% - 2.2rem);
	padding-top: 0.4rem;
	overflow: auto;
	position: relative;
	img {
		height: auto;
	}
}
.bntText {
	width: 100%;
	padding: 0 0.24rem;
	height: 0.76rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0.24rem 0;
	font-size: 0.28rem;
	color: #fff;
	& > div {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #05549a;
		border-radius: 0.36rem;
		height: 0.76rem;
	}
	.btn {
		margin-left: 0.2rem;
	}
}
.paly {
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 0.28rem;
	justify-content: center;
}
</style>
